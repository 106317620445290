




































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import {
  getHealthList,
  getDetailCheck,
  
} from '@/serve/evaluatingBefore/evaluatingRecords';
import {
  getManagePage
  
} from '@/serve/wearable/index';

import { getTenantData } from '@/serve/medicalAppointment/institutionManage.ts';
import { ElForm } from 'element-ui/types/form';
interface Data {
  current?: number;
  size?: number;
  [propName: string]: any;
}
@Component({
  name: 'EvaluatingRecord',
})
export default class EvaluatingRecord extends Vue {
  public currentPage4 = 1;
  public dialogFormVisible = false;
  public form = {
    current: 1,
    size: 10,
    startDate: "",
    endDate: "",
    status: "",
    title: "",
    type:"",
    tenantId:""
  };
  public typeList = [
    {
      id:1,
      label:"血糖",
      value:"1"
    },
    {
      id:2,
      label:"血压",
      value:"2"
    },
    {
      id:3,
      label:"尿酸",
      value:"3"
    },
    {
      id:4,
      label:"血脂",
      value:"4"
    }
  ]
  public date = [];
  public tableData = [
    {
      recdors: [
        {
          jkglCheckerVO: [
            {
              checkerName: '',
              checkerPhone: '',
              checkerIdCard: '',
              updateTime: '',
            },
          ],
        },
      ],
    },
  ];
  public dialogForm = [];
  public detailList = [];
  public tenantName = '';
  public restaurants = [];
  public itemListNew = {}
  public content = {}
  public isAdmin = sessionStorage.getItem('isAdmin');
  public async created() {
    this.getList();
  }
  public mounted() {
    this.getTenantData();
  }
  /**获取全部租户 */
  public async getTenantData(): Promise<void> {
    const res = await getTenantData({ size: 100000, current: 1 });
    this.restaurants = res.data.records;
  }
  public handleSelect(e: any) {
    if (Object.keys(e).length > 0) this.form.tenantId = e.tId;
    else this.form.tenantId = '';
  }
  public async querySearch(queryString: any, cb: any) {
    const res = await getTenantData({
      size: 100,
      current: 1,
      name: queryString,
    });
    const restaurants = res.data.records;
    const newArr: any = [];
    restaurants.forEach((x: any) => {
      if (x.name.includes(queryString)) {
        newArr.push({ value: x.name, tId: x.id });
      }
    });
    // 调用 callback 返回建议列表的数据
    cb(newArr);
  }
  public async getList() {
    // 如果form的数据有'' 则赋值为null 由于后端也会查询''类型的值
    
    const res = await getManagePage(this.form);
    console.log("///",res);
    
    this.tableData = await res.data;
  }
  // 筛选功能
  public onSubmit() {
    if (this.date) {
      // 绑定的date的值 然后是个数组赋值给form
      this.form.startDate = this.date[0];
      this.form.endDate = this.date[1];
    } else {
      this.form.startDate = '';
      this.form.endDate = '';
    }
    // 点击筛选后 初始化值
    this.currentPage4 = 1;
    this.form.size = 10;
    this.form.current = 1;
    this.getList();
  }
  // 重置功能
  public resetForm(formName: string) {
    (this.$refs[formName] as ElForm).resetFields();
    this.currentPage4 = 1;
    this.tenantName = '';
    this.form.tenantId = '';
    this.date =[];
    this.form.startDate = '';
    this.form.endDate = '';
    this.getList();
  }
  // 详情按钮点击
  public async readMore(index: number, item: any) {
    
    const itemList: any = item
    if (typeof(itemList.content) === 'string') {
      itemList.content = JSON.parse(itemList.content)
    }
   
    this.itemListNew = itemList
    this.content = itemList.content
    this.dialogFormVisible = true;
    console.log('item',itemList);
  }
  // 点击左右箭头
  public handleSizeChange(val: any): void {
    this.form.size = val;
    this.getList();
  }
  // 点击页码
  public handleCurrentChange(val: any): void {
    this.form.current = val;
    this.getList();
  }
  /** 清空机构名称事件 */
  public clearName() {
    this.form.tenantId = '';
  }
}
